<template>
    <div class="articleinfo">
        <!-- 头部通用 -->
        <Header />

        <!-- 内容区域 -->
        <div class="content">
            <div class="main">
                <div class="article">
                    <!-- 文章标题 -->
                    <div class="title">{{ article.title }}</div>
                    <!-- 发布时间 -->
                    <div class="pubtime">发布日期：{{ article.pub_time }}</div>
                    <!-- 是否有视频 -->
                    <div class="video" v-if="article.video != ''">
                        <video :src="article.video" controls></video>
                    </div>
                    <!-- 文章内容 -->
                    <div class="body" v-html="article.body"></div>
                </div>
            </div>
        </div>

        <!-- 底部通用 -->
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
    components: { Header, Footer },
    metaInfo() {
        return {
            title: this.article.title + '_长兴新闻网',
            meta: [
                {
                    name: 'keywords',
                    content: process.env.VUE_APP_KEYWORDS,
                },
                {
                    name: 'description',
                    content: process.env.VUE_APP_DESCRIPTION,
                },
            ],
        }
    },
    data() {
        return {
            article_id: 0,
            article: {
                title: '',
                video: '',
            },
        }
    },
    created() {
        if (this.$route.params.id) {
            this.article_id = this.$route.params.id
            this.getArticleInfo()
        }
    },
    methods: {
        getArticleInfo() {
            this.$api
                .article_info({
                    id: this.article_id,
                })
                .then((res) => {
                    // console.log(res)
                    if (res.content_type == 5) {
                        window.location.href = res.link
                    } else {
                        this.article = res
                        this.article.body = this.htmlDecode(this.article.body)
                    }
                })
        },
        // html转码
        htmlEncode(str) {
            if (str.length === 0) return ''
            let s = ''
            s = str.replace(/&/g, '&amp;')
            s = s.replace(/</g, '&lt;')
            s = s.replace(/>/g, '&gt;')
            // s = s.replace(/ /g, "");
            //s = s.replace(/\'/g, '&#39;')
            //s = s.replace(/\"/g, '&quot;')
            s = s.replace(/\n"/g, '')
            s = s.replace(/\r"/g, '')
            return s
        },
        // html解码
        htmlDecode(str) {
            if (str.length == 0) return ''
            let s = ''
            s = str.replace(/&amp;/g, '&')
            s = s.replace(/&lt;/g, '<')
            s = s.replace(/&gt;/g, '>')
            s = s.replace(/&nbsp;/g, ' ')
            s = s.replace(/&#39;/g, "'")
            s = s.replace(/&quot;/g, '"')
            return s
        },
    },
}
</script>
